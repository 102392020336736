
/* injects from baggage-loader */
require('./style.sass');

import React from 'react'

const PageScroll = () => (
  <div className='page-scroll' />
)

export default PageScroll
