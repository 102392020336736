export const BACKGROUND_INFO_TIMEOUT = 1200
export const BACKGROUND_WRAPPER_PREFIX = 'background__wrapper--'
export const BACKGROUND_WRAPPER_SUBCLASSES = [
  'tunnel',
  'welcome',
  'newsstand',
  'xp',
  'webdev',
  'arcade',
  'mobile',
  'lab',
  'contact'
]
