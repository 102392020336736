
/* injects from baggage-loader */
require('./style.sass');

import React from 'react'

const JumpingShadow = () => (
  <div className='jumping-shadow' />
)

export default JumpingShadow
