
/* injects from baggage-loader */
require('./style.sass');

import React from 'react'

const Lamps = () => (
  <div className='lamps' />
)

export default Lamps
